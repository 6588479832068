<template>
  <div>
    <pwd-contair>
      <div slot="menu">密码重置</div>
      <div slot="pwdContair">
        <div>
          <step-contair :active="1"></step-contair>
          <div>
            <get-captcha></get-captcha>
          </div>
        </div>
      </div>
    </pwd-contair>
  </div>
</template>
<script>
import PwdContair from "@/components/supplier/pwdContair/pwdContair.vue";
import StepContair from "@/components/supplier/pwdContair/stepContair.vue";
import GetCaptcha from '@/components/supplier/pwdChange/getCaptcha.vue';
export default {
    components: { GetCaptcha, PwdContair, StepContair,  },
};
</script>
<style lang="scss" scoped>
</style>
