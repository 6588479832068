<template>
  <div style="display: flex; justify-content: center;">
    <div class="getverfiy">
      <div class="Pbm80 Plf80 Prt80 tan">
        <img src="../../../assets/supplier/phone-verfiy.png" />
        <div class="Mtp15">使用手机短信验证码</div>
        <div class="Mtp40 yanzheng co_7474">
          <span
            >当前手机号：<span>{{ userInfo.mobile }}</span></span
          >
          <div class="verfiyNum Mtp20">
            <el-row :gutter="5">
              <el-col :span="14">
                <el-input
                  v-model="code"
                  placeholder="请输入手机验证码"
                ></el-input>
              </el-col>
              <el-col :span="10">
                <verfiy-btn @sendKey = "getKey($event)"  :mobile="userInfo.mobile"></verfiy-btn>
                <!-- <el-button
                  type="primary"
                  :disabled="(disabled = !show)"
                  @click="getVerfiy()"
                  ><span>获取验证码</span><strong v-if="!show" class="F14">|</strong>
                  <span v-show="!show" class="count"
                    >{{ Verfiycount }}s</span
                  ></el-button
                > -->
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="Mtp50">
          <el-button type="primary" @click="submit()">提交认证</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { checkMobileCodeByKey } from "@/api/user";
import { mapGetters } from "vuex";
import VerfiyBtn from '@/components/verfiyBtn/verfiyBtn';

export default {
  components: { VerfiyBtn },
  data() {
    return {
      /* 验证码倒计时 */
      // show: true,
      // Verfiycount: "", // 初始化次数
      // timer: null,
      key: "",
      code: "",
    };
  },
  created() {
    this.getKey()
  },
  methods: {
    //获取验证码
    // getVerfiy() {
    //   //发送手机验证码获取key
    //   getMobileCode(this.userInfo.mobile).then((res) => {
    //     if (res.success) {
    //       this.key = res.data;
    //       //获取验证码成功之后倒计时
    //       const TIME_COUNT = 60; //更改倒计时时间
    //       if (!this.timer) {
    //         this.Verfiycount = TIME_COUNT;
    //         this.show = false;
    //         this.timer = setInterval(() => {
    //           if (this.Verfiycount > 0 && this.Verfiycount <= TIME_COUNT) {
    //             this.Verfiycount--;
    //           } else {
    //             this.show = true;
    //             clearInterval(this.timer); // 清除定时器
    //             this.timer = null;
    //           }
    //         }, 1000);
    //       }
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   });
    // },
    getKey(val) {
      this.key = val
      console.log(this.key)
    },
    submit() {
      if (this.key == undefined || this.key == "") {
        this.$message({
          type: 'warning',
          message: '请获取验证码！'
        })
        return false
      }
      if (this.code == undefined || this.code == "") {
        this.$message({
          type: 'warning',
          message: '请输入验证码！'
        })
        return false
      }
      //key和输入的验证码进行对比
      checkMobileCodeByKey(this.code || "", this.key || "").then((res) => {
        if (res.success) {
          this.$message.success(res.msg);
          this.$router.push("/supplier/supplierHome/writerPwd");
          
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
 
};
</script>
<style lang="scss" scoped>
.getverfiy {
  width: 520px;
  .tan {
    text-align: center;
    .yanzheng {
      text-align: left;
    }
    .el-button {
      width: 100%;
    }
  }
}
strong {
  margin: 0 4px;
}
</style>
